import { Text } from "@fscrypto/flip-kit/text";
import { motion } from "framer-motion";
import { truncateAddress } from "~/utils/truncate-address";

interface ScoreMeterProps {
  score: number;
  address: string;
  min?: number;
  max?: number;
}

export const ScoreMeter = ({ score, address, min = 0, max = 15 }: ScoreMeterProps) => {
  const startAngle = -180;
  const endAngle = 0;
  const startAngleOffset = -180 - startAngle;
  const endingAngle = (endAngle > 90 ? 90 : endAngle < -90 ? -90 : endAngle) + 90 - startAngleOffset;
  const endingDenominator = (endingAngle / 180) * 2;
  const radius = 60;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;
  const normalizedValue = (score - min) / (max - min);
  const drawnCircumference = ((startAngle + endAngle) / -360) * circumference;
  const offset = normalizedValue * drawnCircumference;

  return (
    <div className="relative flex h-full items-center justify-center w-full max-w-[280px] py-2 px-0">
      <div className="absolute w-[130px] h-[130px] rounded-full bg-gradient-to-br from-primary/5 to-primary/10 blur-xl" />
      <div className="flex flex-col items-center w-full">
        <motion.svg
          viewBox="0 0 140 75"
          className="w-full relative z-10"
          aria-hidden="true"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <defs>
            <linearGradient id="scoreGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#FF5312" />
              <stop offset="50%" stopColor="#FF8C00" />
              <stop offset="100%" stopColor="#FEBB73" />
            </linearGradient>
            <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feComposite in="SourceGraphic" in2="blur" operator="over" />
            </filter>
          </defs>
          <circle
            cx="70"
            cy="70"
            r={radius}
            fill="none"
            className="stroke-[#353535]"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={circumference * ((endingDenominator + 1) / 4)}
            transform={`rotate(${startAngle}, 70, 70)`}
          />
          <motion.circle
            cx="70"
            cy="70"
            r={radius}
            fill="none"
            stroke="url(#scoreGradient)"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - offset}
            transform={`rotate(${startAngle}, 70, 70)`}
            filter="url(#glow)"
            initial={{ strokeDashoffset: circumference }}
            animate={{ strokeDashoffset: circumference - offset }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          />
        </motion.svg>

        <motion.div
          className="flex flex-col items-center -mt-20"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 1.0,
              type: "spring",
              stiffness: 200,
            }}
          >
            <Text variant="section-headline" className="block text-center text-6xl font-medium text-white/90">
              {score}
            </Text>
          </motion.div>
          <Text variant="caption" size="sm" className="block text-center mt-1 text-white/70">
            Your Score
          </Text>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 1.2 }}>
            <Text
              variant="caption"
              size="xs"
              className="block text-center text-white/70 mt-2 px-2 py-0.5 bg-background/20 backdrop-blur-sm rounded-full border border-border/10"
            >
              {truncateAddress(address, { maxLength: 20 })}
            </Text>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};
