import { MapIcon } from "lucide-react";

interface Props {
  children: React.ReactNode;
}

export const JourneyList = ({ children }: Props) => {
  return (
    <div className="py-10">
      <div className="flex items-center gap-4 pt-8 pb-4">
        <div className="size-10 rounded-md border-white/15 border flex items-center justify-center">
          <MapIcon className="size-5 text-white/70" />
        </div>
        <h2 className="text-3xl font-normal tracking-tight text-foreground pb-1">Journeys</h2>
      </div>
      <p className="text-lg text-gray-200/70 mb-4 max-w-screen-lg">
        Curated paths of connected quests designed to guide you through the ecosystem. Complete onboarding journeys to
        unlock more advanced journeys and exclusive quests with greater rewards.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-6">{children}</div>
    </div>
  );
};
