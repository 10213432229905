import type { JourneyQuestView, JourneyView } from "@fscrypto/domain/earn";
import { Link } from "@remix-run/react";
import { cva } from "class-variance-authority";
import { ArrowRightIcon, CheckIcon, LockIcon } from "lucide-react";
import { PartnerIcon } from "~/components/partner-icon";
import { TokenIcon } from "~/components/token-icon";
import { Badge } from "~/components/ui/badge";
import { cn } from "~/lib/utils";
import { AnimatedGradientText } from "../ui/animated-gradient-text";

interface Props {
  journey: JourneyView;
}

export const JourneyListCard = ({ journey }: Props) => {
  const journeyUrl = `/journey/${journey.slug}`;

  return (
    <div className="bg-muted/50 rounded-xl min-h-80 flex flex-col overflow-hidden transition-all duration-300 hover:translate-y-[-2px] relative group hover:border-white/10">
      {/* Card overlay link - positioned absolutely to cover the entire card */}
      <Link to={journeyUrl} className="absolute inset-0 z-10">
        <span className="sr-only">View {journey.title}</span>
      </Link>

      <header className="p-4 flex justify-between items-center">
        <Link to="/" className="flex items-center group relative z-20">
          <PartnerIcon slug={journey.partnerSlug} className="size-8 m-0 mr-2" />
          <span className="text-base font-normal text-white/70 group-hover:text-white/90 transition-colors">
            {journey.partnerDisplayName}
          </span>
        </Link>
        {journey.tokenSymbol ? (
          <div className="bg-white/5 hover:bg-white/10 border-0 text-white/80 px-2.5 pl-1 py-0.5 tracking-wide rounded-full flex items-center">
            <TokenIcon symbol={journey.tokenSymbol} className="h-4 mr-1.5" />
            {journey.rewardType === "TOKEN_PERCENT" && <span className="text-white/60 mr-1 text-xs">Up to </span>}
            <span className="font-normal">{journey.tokenAmountAggregated}</span>{" "}
            <span className="text-white/60 ml-0.5">{journey.tokenSymbol}</span>
          </div>
        ) : null}
      </header>

      <div className="px-4 flex flex-col space-y-1">
        <h3 className="text-3xl tracking-tight font-normal text-foreground">{journey.title}</h3>
        {journey.isOnboarding ? (
          <AnimatedGradientText
            colorFrom="#FF409B"
            colorTo="#4CD0F1"
            className="inline uppercase text-xs tracking-wide"
          >
            Onboarding
          </AnimatedGradientText>
        ) : null}
      </div>

      <div aria-hidden className="flex-1" />

      <ul className="p-4 gap-x-2 text-sm">
        {journey.quests.map((quest) => (
          <JourneyCardQuest key={quest.id} quest={quest} />
        ))}
      </ul>

      {/* Footer link needs z-index to appear above the overlay */}
      <div className="relative z-20">
        <JourneyCardFooter journey={journey} />
      </div>
    </div>
  );
};

const questBadgeVariants = cva("inline-flex rounded-full text-xs whitespace-nowrap font-normal border-0 my-1 mr-1", {
  variants: {
    state: {
      LOCKED: "bg-white/5 text-white/50",
      ELIGIBLE: "bg-white/5 text-white/50",
      STARTED: "bg-violet-500/20 text-violet-400",
      IN_PROGRESS: "bg-violet-500/20 text-violet-400",
      COMPLETED: "bg-emerald-500/30 text-emerald-400",
      INELIGIBLE: "bg-emerald-500/30 text-emerald-400",
      CLAIMED: "bg-emerald-500/30 text-emerald-400",
    },
  },
  defaultVariants: {
    state: "LOCKED",
  },
});

function JourneyCardQuest({ quest }: { quest: JourneyQuestView }) {
  // Map the questEntryState to our variant state
  const badgeState = quest.questEntryState ?? "LOCKED";

  return (
    <Badge variant="outline" className={questBadgeVariants({ state: badgeState })}>
      {quest.questEntryState === "COMPLETED" ? <CheckIcon className="size-2.5 mr-1" /> : null}
      {quest.title}
    </Badge>
  );
}

function JourneyCardFooter({ journey }: { journey: JourneyView }) {
  const journeyUrl = `/journey/${journey.slug}`;
  const baseClasses =
    "w-full p-3 px-4 flex justify-between items-center text-xl font-normal transition-colors tracking-wide";

  if (journey.userState === "ELIGIBLE") {
    return (
      <Link
        to={journeyUrl}
        className={cn(
          baseClasses,
          "text-white/90 bg-gradient-to-r from-transparent to-emerald-950/10 hover:from-transparent hover:to-emerald-950/20",
        )}
      >
        Start Journey
        <div className="bg-emerald-500/10 p-1.5 rounded-full transition-colors group-hover:bg-emerald-500">
          <ArrowRightIcon className="size-4.5 stroke-[1.25] text-emerald-400/90 transition-colors group-hover:text-black" />
        </div>
      </Link>
    );
  }

  if (journey.userState === "STARTED") {
    return (
      <Link
        to={journeyUrl}
        className={cn(
          baseClasses,
          "text-white/80 bg-gradient-to-r from-transparent to-blue-950/10 hover:from-transparent hover:to-blue-950/20",
        )}
      >
        Continue Journey
        <div className="bg-blue-500/10 p-1.5 rounded-full transition-colors group-hover:bg-blue-500">
          <ArrowRightIcon className="size-4.5 stroke-[1.25] text-blue-400/90 transition-colors group-hover:text-black" />
        </div>
      </Link>
    );
  }

  if (journey.userState === "COMPLETED") {
    return (
      <Link
        to={journeyUrl}
        className={cn(
          baseClasses,
          "text-white/70 bg-gradient-to-r from-transparent to-purple-950/10 hover:from-transparent hover:to-purple-950/20",
        )}
      >
        Completed
        <div className="bg-purple-500/10 p-1.5 rounded-full transition-colors group-hover:bg-purple-500">
          <CheckIcon className="size-4.5 stroke-[1.25] text-purple-400/90 transition-colors group-hover:text-black" />
        </div>
      </Link>
    );
  }

  if (journey.userState === "LOCKED") {
    return (
      <Link
        to={journeyUrl}
        className={cn(
          baseClasses,
          "text-white/70 bg-gradient-to-r from-transparent to-amber-950/10 hover:from-transparent hover:to-amber-950/20",
        )}
      >
        View Prerequisites
        <div className="bg-amber-500/10 p-1.5 rounded-full transition-colors group-hover:bg-amber-500">
          <LockIcon className="size-4.5 stroke-[1.25] text-amber-400/90 transition-colors group-hover:text-black" />
        </div>
      </Link>
    );
  }

  return null;
}
