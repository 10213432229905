import type { QuestListItemView } from "@fscrypto/domain/earn";
import { Link } from "@remix-run/react";
import { ArrowRightIcon, CheckIcon, ClockIcon, LockIcon } from "lucide-react";
import { cn } from "~/lib/utils";
import { PartnerIcon } from "./partner-icon";
import { QuestPrereqHoverCard } from "./quest-prereq-hover-card";
import { TokenIcon } from "./token-icon";
import { Badge } from "./ui/badge";
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";

interface Props {
  questListItem: QuestListItemView;
}

export const QuestCard = ({ questListItem }: Props) => {
  return (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-lg hover:shadow-primary/10 hover:translate-y-[-2px] bg-gradient-to-br from-muted/80 to-muted/40 border border-white/5 h-[310px] flex flex-col">
      <CardHeader className="p-4 pb-1 space-y-0">
        <div className="flex justify-between items-center">
          <Link to={`/${questListItem.partnerSlug}`} className="flex gap-x-2 items-center group">
            <div className="bg-white/5 p-1.5 rounded-full transition-colors group-hover:bg-white/10">
              <PartnerIcon slug={questListItem.partnerSlug} className="size-6" />
            </div>
            <span className="text-base font-normal text-white/70 group-hover:text-white/90 transition-colors tracking-wide">
              {questListItem.partnerDisplayName}
            </span>
          </Link>
          {(questListItem.rewardType === "TOKEN_PERCENT" || questListItem.rewardType === "TOKEN_FIXED") && (
            <Badge
              variant="outline"
              className="bg-white/5 hover:bg-white/10 border-0 text-white/80 px-2 py-1 tracking-wide"
            >
              <TokenIcon symbol={questListItem.tokenSymbol ?? ""} className="h-4 mr-1.5" />
              {questListItem.rewardType === "TOKEN_PERCENT" && (
                <span className="text-white/60 mr-1 text-xs">Up to</span>
              )}
              <span className="font-normal text-base">{questListItem.maxRewardAmount}</span>{" "}
              <span className="text-white/60 ml-1 text-base">{questListItem.tokenSymbol}</span>
            </Badge>
          )}

          {questListItem.rewardType === "FLOW_POINTS" && (
            <Badge
              variant="outline"
              className="bg-white/5 hover:bg-white/10 border-0 text-white/80 px-2.5 py-0.5 tracking-wide"
            >
              <span className="font-normal text-base">{questListItem.maxRewardAmount} Flow Points</span>
            </Badge>
          )}

          {questListItem.rewardType === "LOTTERY" && (
            <Badge
              variant="outline"
              className="bg-white/5 hover:bg-white/10 border-0 text-white/80 px-2.5 py-0.5 tracking-wide"
            >
              <span className="font-normal text-base">Lottery</span>
            </Badge>
          )}
        </div>
      </CardHeader>

      <CardContent className="p-4 pt-2 pb-6 flex-1 flex flex-col justify-between">
        <div>
          <h3 className="text-2xl font-normal text-foreground/90 line-clamp-2 leading-normal tracking-wide min-h-[60px]">
            <Link to={`/quest/${questListItem.slug}`}>{questListItem.title}</Link>
          </h3>
          <div className="flex flex-wrap gap-2 pt-2 min-h-[32px]">
            {questListItem.tags?.map((tag) => (
              <Badge
                key={tag}
                variant="outline"
                className="bg-blue-500/5 border-blue-500/10 text-gray-400/90 px-2 py-0.5 text-sm font-normal"
              >
                {tag}
              </Badge>
            ))}
          </div>
        </div>

        <div className="pt-4">
          <QuestStatusIndicator questListItem={questListItem} />
        </div>
      </CardContent>

      <CardFooter className="p-0">
        <QuestCardFooter questListItem={questListItem} />
      </CardFooter>
    </Card>
  );
};

function QuestStatusIndicator({ questListItem }: { questListItem: QuestListItemView }) {
  const { questEntryState, prereqQuestTitle, prereqQuestSlug } = questListItem;

  if (questListItem.questEntryState === "ELIGIBLE") {
    return (
      <div className="flex items-center text-sm">
        <Badge
          variant="outline"
          className="bg-emerald-500/5 border-emerald-500/10 text-emerald-400/90 px-2.5 py-1 text-sm font-normal tracking-wide"
        >
          Ready to start
        </Badge>
      </div>
    );
  }

  if (questEntryState === "STARTED") {
    return (
      <div className="flex items-center text-sm">
        <Badge
          variant="outline"
          className="bg-blue-500/5 border-blue-500/10 text-blue-400/90 px-2.5 py-1 text-sm font-normal tracking-wide"
        >
          <ClockIcon className="size-3.5 mr-1 opacity-80" />
          In progress
        </Badge>
      </div>
    );
  }

  if (questEntryState === "COMPLETED") {
    return (
      <div className="flex items-center text-sm">
        <Badge
          variant="outline"
          className="bg-purple-500/5 border-purple-500/10 text-purple-400/90 px-2.5 py-1 text-sm font-normal tracking-wide"
        >
          <CheckIcon className="size-3.5 mr-1 opacity-80" />
          Completed
        </Badge>
      </div>
    );
  }

  if (questEntryState === "LOCKED") {
    return (
      <div className="flex items-center text-sm">
        <Badge
          variant="outline"
          className="bg-amber-500/5 border-amber-500/10 text-amber-400/90 px-2.5 py-1 text-sm font-normal tracking-wide"
        >
          <QuestPrereqHoverCard prereqQuestSlug={prereqQuestSlug ?? ""} prereqQuestTitle={prereqQuestTitle ?? ""} />
        </Badge>
      </div>
    );
  }

  return null;
}

function QuestCardFooter({ questListItem }: { questListItem: QuestListItemView }) {
  const questUrl = `/quest/${questListItem.slug}`;
  const baseClasses =
    "w-full border-t border-white/5 flex justify-between items-center text-lg font-normal transition-colors tracking-wide py-4 px-6";

  if (questListItem.questEntryState === "ELIGIBLE") {
    return (
      <Link to={questUrl} className={cn(baseClasses, "text-white/90 bg-black/20 hover:bg-black/30")}>
        <span>Start Quest</span>
        <div className="bg-emerald-500/10 p-2 rounded-full">
          <ArrowRightIcon className="size-5 text-emerald-400/90" />
        </div>
      </Link>
    );
  }

  if (questListItem.questEntryState === "STARTED") {
    return (
      <Link to={questUrl} className={cn(baseClasses, "text-white/80 bg-black/20 hover:bg-black/30")}>
        <span>Continue Quest</span>
        <div className="bg-blue-500/10 p-2 rounded-full">
          <ArrowRightIcon className="size-5 text-blue-400/90" />
        </div>
      </Link>
    );
  }

  if (questListItem.questEntryState === "COMPLETED") {
    return (
      <Link to={questUrl} className={cn(baseClasses, "text-white/70 bg-black/20 hover:bg-black/30")}>
        <span>View Details</span>
        <div className="bg-purple-500/10 p-2 rounded-full">
          <CheckIcon className="size-5 text-purple-400/90" />
        </div>
      </Link>
    );
  }

  if (questListItem.questEntryState === "LOCKED") {
    return (
      <Link to={questUrl} className={cn(baseClasses, "text-white/70 bg-black/20 hover:bg-black/30")}>
        <span>View Prerequisites</span>
        <div className="bg-amber-500/10 p-2 rounded-full">
          <LockIcon className="size-5 text-amber-400/90" />
        </div>
      </Link>
    );
  }

  return null;
}
