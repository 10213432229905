import { useMemo } from "react";
import { useCurrentUser } from "~/hooks/use-current-user";
import { tracking } from "~/utils/tracking";
import { trpc } from "~/utils/trpc";

export function useScoreCard(chainId: string) {
  const user = useCurrentUser();
  const utils = trpc.useUtils();

  const userWallets = trpc.public.userWallets.byChainId.useQuery({ chainId: chainId }, { enabled: user !== null });

  const defaultUserWallet = useMemo(() => userWallets.data?.find((wallet) => wallet.isDefault), [userWallets.data]);

  const score = trpc.public.userWallets.latestScore.useQuery(
    // biome-ignore lint/style/noNonNullAssertion: This is the recommended way https://tkdodo.eu/blog/react-query-and-type-script#type-safety-with-the-enabled-option
    { walletId: defaultUserWallet?.wallet.id! },
    { enabled: !!defaultUserWallet },
  );

  const setDefault = trpc.public.userWallets.setDefault.useMutation({
    onSuccess: (_data, variables) => {
      tracking("wallet_set_default", "Earn", {
        chain: chainId,
        walletId: variables.walletId,
      });
    },
  });

  return {
    defaultUserWallet,
    userWallets,
    score,
    setDefault: async (walletId: string) => {
      if (!user?.id) throw new Error("User should be logged in");
      await setDefault.mutateAsync({ userId: user.id, walletId });
      utils.public.userWallets.byChainId.invalidate({ chainId });
    },
    isLoading: userWallets.isLoading || score.isLoading || setDefault.isPending,
    isAuthenticated: user !== null,
  };
}
