import type { QuestListItemView } from "@fscrypto/domain/earn";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fscrypto/flip-kit/table";
import { Text } from "@fscrypto/flip-kit/text";
import { Link, useNavigate } from "@remix-run/react";
import { ArrowRightIcon, CheckIcon, ClockIcon, LockIcon } from "lucide-react";
import { cn } from "~/lib/utils";
import { PartnerIcon } from "./partner-icon";
import { QuestPrereqHoverCard } from "./quest-prereq-hover-card";
import { Badge } from "./ui/badge";

export const QuestTableNoRewards = ({ questListItems }: { questListItems: QuestListItemView[] }) => {
  if (questListItems.length === 0) {
    return (
      <Text variant="body-lg" className="block py-9 text-center">
        No quests available
      </Text>
    );
  }

  return (
    <>
      <div className="block md:hidden">
        <QuestTableMobile questListItems={questListItems} />
      </div>
      <div className="md:block hidden">
        <QuestTableDesktop questListItems={questListItems} />
      </div>
    </>
  );
};

const QuestTableMobile = ({ questListItems }: { questListItems: QuestListItemView[] }) => {
  const navigate = useNavigate();
  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow>
          <TableHead className="text-left">Quest</TableHead>
          <TableHead className="text-right">Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {questListItems.map((questListItem) => {
          return (
            <TableRow
              key={questListItem.id}
              className="cursor-pointer hover:bg-white/5 transition-colors"
              onClick={() => {
                navigate(`/quest/${questListItem.slug}`);
              }}
            >
              <TableCell className="text-left py-3">
                <div className="flex flex-row items-center gap-2">
                  <PartnerIcon slug={questListItem.partnerSlug} className="size-6 flex-shrink-0" />
                  <div className="flex flex-col">
                    <Link to={`/quest/${questListItem.slug}`} className="hover:text-primary transition-colors">
                      <Text variant="body" className="font-medium text-sm">
                        {questListItem.title}
                      </Text>
                    </Link>
                    <Text variant="caption" className="text-muted-foreground text-xs">
                      {questListItem.partnerDisplayName}
                    </Text>
                  </div>
                </div>
              </TableCell>
              <TableCell className="text-right">
                <QuestStatusBadge questListItem={questListItem} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const QuestTableDesktop = ({ questListItems }: { questListItems: QuestListItemView[] }) => {
  const navigate = useNavigate();
  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow>
          <TableHead className="text-left">Quest</TableHead>
          <TableHead className="text-left">Tags</TableHead>
          <TableHead className="text-right">Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {questListItems.map((questListItem) => {
          return (
            <TableRow
              key={questListItem.id}
              className="cursor-pointer hover:bg-white/5 transition-colors"
              onClick={() => {
                navigate(`/quest/${questListItem.slug}`);
              }}
            >
              <TableCell className="text-left py-4">
                <div className="flex flex-row items-center gap-3">
                  <PartnerIcon slug={questListItem.partnerSlug} className="size-9 flex-shrink-0" />
                  <div className="flex flex-col">
                    <Link to={`/quest/${questListItem.slug}`} className="hover:text-primary transition-colors">
                      <Text variant="body" className="font-medium">
                        {questListItem.title}
                      </Text>
                    </Link>
                    <div className="flex items-center gap-1 mt-1">
                      <Text variant="caption" className="text-muted-foreground">
                        {questListItem.partnerDisplayName}
                      </Text>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="text-left">
                {questListItem.tags.map((tag) => {
                  return (
                    <Badge
                      key={tag}
                      variant="outline"
                      className="bg-gray-500/5 border-gray-500/10 text-gray-400/90 px-2 py-0.5 text-sm font-normal"
                    >
                      {tag}
                    </Badge>
                  );
                })}
              </TableCell>
              <TableCell className="text-right">
                <QuestStatusBadge questListItem={questListItem} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

function QuestStatusBadge({ questListItem }: { questListItem: QuestListItemView }) {
  const { questEntryState, prereqQuestSlug, prereqQuestTitle } = questListItem;
  if (questEntryState === "ELIGIBLE") {
    return (
      <Badge
        variant="outline"
        className="bg-emerald-500/5 border-emerald-500/10 text-emerald-400/90 px-2 py-0.5 text-xs font-normal"
      >
        Ready to start
      </Badge>
    );
  }

  if (questEntryState === "STARTED") {
    return (
      <Badge
        variant="outline"
        className="bg-blue-500/5 border-blue-500/10 text-blue-400/90 px-2 py-0.5 text-xs font-normal"
      >
        <ClockIcon className="size-3 mr-1 opacity-80" />
        In progress
      </Badge>
    );
  }

  if (questEntryState === "COMPLETED") {
    return (
      <Badge
        variant="outline"
        className="bg-purple-500/5 border-purple-500/10 text-purple-400/90 px-2 py-0.5 text-xs font-normal"
      >
        <CheckIcon className="size-3 mr-1 opacity-80" />
        Completed
      </Badge>
    );
  }

  if (questEntryState === "LOCKED") {
    return (
      <Badge
        variant="outline"
        className="bg-amber-500/5 border-amber-500/10 text-amber-400/90 px-2 py-0.5 text-xs font-normal"
      >
        <QuestPrereqHoverCard prereqQuestSlug={prereqQuestSlug ?? ""} prereqQuestTitle={prereqQuestTitle ?? ""} />
      </Badge>
    );
  }

  return null;
}
