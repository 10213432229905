import type { Chain } from "@fscrypto/domain/chain";
import type { UserWalletWithWalletAndChain } from "@fscrypto/domain/earn";
import { ChevronDown } from "lucide-react";
import { useEffect } from "react";
import ConnectWallet from "~/components/connect-wallet";
import { useScoreCard } from "~/hooks/use-score-card";
import { ScoreCardDetails } from "./score-card-details";
import { ScoreMeter } from "./score-meter";
import { Button } from "./ui/button";
import { Skeleton } from "./ui/skeleton";

export default function ScoreCard({
  chain,
  scoreWallet,
}: { chain: Chain; scoreWallet?: UserWalletWithWalletAndChain }) {
  const { score, setDefault, isLoading, isAuthenticated, defaultUserWallet } = useScoreCard(chain.id);

  useEffect(() => {
    if (!isLoading && !defaultUserWallet && scoreWallet) {
      setDefault(scoreWallet.walletId);
    }
  }, [isLoading, defaultUserWallet, scoreWallet, setDefault]);

  const totalScore = score.data?.totalScore;
  const address = defaultUserWallet?.wallet.address;

  if (!isAuthenticated) return null;

  return (
    <div className="border border-border/20 shadow-xl rounded-xl overflow-hidden max-w-[850px] mx-auto mt-6">
      <div className="p-2 md:p-3">
        {isLoading ? (
          <div className="flex justify-center items-center py-6">
            <Skeleton className="w-[250px] h-[120px] rounded-xl opacity-50" />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row md:items-center gap-1 md:gap-0 justify-between">
            {/* Left side: Score meter and button */}
            <div className="md:w-[48%] flex flex-col items-center justify-center gap-3 order-1">
              {totalScore !== undefined && address ? (
                <ScoreMeter score={totalScore} address={address} />
              ) : (
                <div className="flex flex-col items-center justify-center py-6 px-2 text-center">
                  <p className="text-primary-foreground/50 mb-3">Select a wallet to view your score</p>
                </div>
              )}
              <ConnectWallet chain={chain} onSelect={(uw) => setDefault(uw.walletId)}>
                <Button
                  variant="secondary"
                  className="w-auto px-3 py-1.5 text-sm shadow-sm hover:shadow-md transition-all duration-200 rounded-lg opacity-90 hover:opacity-100"
                >
                  {totalScore !== undefined ? "Update wallet" : "Connect wallet"}
                </Button>
              </ConnectWallet>
            </div>

            {/* Right side: Sub-scores */}
            {score?.data && (
              <div className="md:w-[52%] flex-shrink-0 order-2">
                <ScoreCardDetails score={score.data} chain={chain} compact={true} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
