import type { Partner } from "@fscrypto/database";
import type { Chain } from "@fscrypto/domain/chain";
import type { UserWalletWithWalletAndChain } from "@fscrypto/domain/earn";
import { Text } from "@fscrypto/flip-kit/text";
import { PartnerList } from "./partner-list";
import ScoreCard from "./score-card";

// This can eventually be removed. Temporary for Axelar.
const partnerTitle = (partner: Partner) => {
  if (partner.slug === "axelar") {
    return "AXELAR AMPLIFIER ADVENTURE";
  }
  return partner.displayName.toUpperCase();
};

export const PartnerHeader = ({
  partners,
  partner,
  chain,
  scoreWallet,
}: {
  partners: Partner[];
  partner?: Partner;
  chain?: Chain;
  scoreWallet?: UserWalletWithWalletAndChain;
}) => {
  return (
    <header>
      <div className="flex items-center justify-between gap-4 flex-col sm:flex-row mb-12">
        <div className="flex flex-col gap-4 max-w-lg">
          {partner ? (
            <>
              <Text variant="section-headline" className="block">
                {partnerTitle(partner)}
              </Text>
              <Text className="block text-text-tertiary">{partner.description}</Text>
            </>
          ) : (
            <>
              <Text variant="section-headline" className="block">
                EXPLORE{" "}
                <span className="bg-gradient-to-r from-questors-secondary to-questors-primary text-transparent bg-clip-text">
                  QUESTS
                </span>
              </Text>
              <Text className="block text-text-tertiary">Earn tokens by learning and completing onchain actions.</Text>
            </>
          )}
        </div>
        <div className="md:min-h-[300px]">{chain && <ScoreCard chain={chain} scoreWallet={scoreWallet} />}</div>
      </div>
      <PartnerList partners={partners} selectedPartner={partner} showAll />
    </header>
  );
};
