import type { Chain } from "@fscrypto/domain/chain";
import type { SnowflakeScore } from "@fscrypto/domain/score";
import { Text } from "@fscrypto/flip-kit/text";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Compact version of the subsection for horizontal layout
const CompactScoreCardSubsection = ({
  score,
  title,
  maxScore,
  icon,
  onHover,
  isActive,
}: {
  score: number;
  title: string;
  maxScore: number;
  icon: string;
  onHover: () => void;
  isActive: boolean;
}) => {
  return (
    <motion.div
      className={`flex items-center gap-1 px-1.5 py-1 rounded-lg cursor-pointer group border ${
        isActive ? "border-accent/50" : "border-transparent hover:border-accent/20"
      } hover:bg-white/3`}
      onMouseEnter={onHover}
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
    >
      <span className="text-lg w-5 flex-shrink-0 mr-2 text-white group-hover:text-white transition-colors duration-200">
        {icon}
      </span>
      <div className="flex justify-between items-center w-full">
        <Text variant="body" className="text-sm font-medium text-white">
          {title}
        </Text>
        <div className="flex items-center gap-0.5">
          <Text variant="body" className="text-sm font-medium text-white">
            {score}
          </Text>
          <Text variant="caption" size="xs" className="text-white/80">
            /{maxScore}
          </Text>
        </div>
      </div>
    </motion.div>
  );
};

// Original vertical subsection component
const ScoreCardSubsection = ({
  score,
  title,
  maxScore,
  onHover,
  isActive,
}: {
  score: number;
  title: string;
  maxScore: number;
  onHover: () => void;
  isActive: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHover();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <motion.div
      className={`flex flex-col cursor-pointer items-center gap-3 px-2 py-2 rounded-lg border ${
        isActive ? "border-accent/50" : "border-transparent hover:border-accent/20"
      } hover:bg-white/3`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
    >
      <div className="flex flex-row items-center gap-1">
        <Text variant="body-lg" className={`font-semibold ${isActive ? "text-white" : "text-white/90"}`}>
          {score}
        </Text>
        <Text variant="caption" className="text-white/70">
          / {maxScore}
        </Text>
      </div>
      <Text
        variant="button"
        size="sm"
        className={`${isHovered || isActive ? "text-white underline" : "text-white/80"} transition-colors`}
      >
        {title}
      </Text>
    </motion.div>
  );
};

const subsections = [
  {
    title: "Activity",
    scoreKey: "activityScore",
    maxScore: 3,
    description:
      "This subscore tracks how active you are on the chain and broadly what kind of transactions you do (e.g simple transfers vs. contract interactions)",
    icon: "📊",
  },
  {
    title: "NFT",
    scoreKey: "nftsScore",
    maxScore: 3,
    description: "This subscore reflects your engagement with NFTs, including minting, buying, selling, and holding.",
    icon: "🖼️",
  },
  {
    title: "Governance",
    scoreKey: "govScore",
    maxScore: 3,
    description:
      "This subscore measures your participation in on-chain governance activities, such as voting on proposals.",
    icon: "🗳️",
  },
  {
    title: "DeFi",
    scoreKey: "defiScore",
    maxScore: 3,
    description:
      "This subscore indicates your involvement in decentralized finance protocols, including lending, borrowing, and liquidity provision.",
    icon: "💰",
  },
  {
    title: "Token",
    scoreKey: "tokensScore",
    maxScore: 3,
    description:
      "This subscore represents your interaction with various tokens, including holding, trading, and participating in token-based ecosystems.",
    icon: "🪙",
  },
] as const;

// Default description when no subscore is selected
const getDefaultDescription = (chain: Chain) => {
  return {
    title: `Your ${chain.name} Score Breakdown`,
    description: "Complete quests to boost your score and unlock higher rewards across the ecosystem.",
    icon: "⭐",
  };
};

export const ScoreCardDetails = ({
  score,
  chain,
  compact = false,
}: { score: SnowflakeScore; chain: Chain; compact?: boolean }) => {
  const [subsection, setSubsection] = useState<(typeof subsections)[number]>(subsections[0]);
  const [hoveredSubsection, setHoveredSubsection] = useState<(typeof subsections)[number] | null>(null);
  const defaultDescription = getDefaultDescription(chain);

  // Determine which subsection to display in the header
  const displayedSubsection = hoveredSubsection || defaultDescription;

  // Add a mouse leave handler for the subsections container
  const handleMouseLeave = () => {
    setHoveredSubsection(null);
  };

  if (compact) {
    return (
      <div className="flex flex-col h-full justify-between gap-2 max-w-[95%] ml-auto">
        <div className="p-2 rounded-lg border border-border/10">
          <div className="flex items-center gap-1.5 mb-0.5">
            <span className="text-lg text-white">{displayedSubsection.icon}</span>
            <Text variant="body" className="text-white font-medium">
              {displayedSubsection.title}
            </Text>
          </div>
          <Text variant="body" className="text-xs text-white/60 line-clamp-3 h-16 min-h-[64px] overflow-hidden">
            {displayedSubsection.description}
          </Text>
        </div>

        <div className="flex flex-col gap-0.5" onMouseLeave={handleMouseLeave}>
          {subsections.map((section) => (
            <CompactScoreCardSubsection
              key={section.title}
              score={score[section.scoreKey]}
              title={section.title}
              maxScore={section.maxScore}
              icon={section.icon}
              onHover={() => setHoveredSubsection(section)}
              isActive={section.title === subsection.title}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between w-full max-w-md min-h-[200px] gap-6">
      <AnimatePresence mode="wait">
        <motion.div
          key={subsection.title}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          className="p-4 rounded-lg border border-border/20"
        >
          <h1 className="text-center text-xl flex items-center justify-center mb-4">
            <span className="mr-2 text-white">{subsection.icon}</span>
            <span className="font-medium text-white">{subsection.title}</span>
          </h1>
          <Text className="text-center text-white/60">{subsection.description}</Text>
        </motion.div>
      </AnimatePresence>

      <div className="flex flex-row justify-between p-2 rounded-lg">
        {subsections.map((section) => (
          <ScoreCardSubsection
            key={section.title}
            score={score[section.scoreKey]}
            title={section.title}
            maxScore={section.maxScore}
            onHover={() => setSubsection(section)}
            isActive={section.title === subsection.title}
          />
        ))}
      </div>
    </div>
  );
};
