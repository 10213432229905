import { Link } from "@remix-run/react";
import { ArrowRightIcon, LockIcon } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";

export const QuestPrereqHoverCard = ({
  prereqQuestSlug,
  prereqQuestTitle,
}: { prereqQuestSlug: string; prereqQuestTitle: string }) => {
  if (!prereqQuestSlug || !prereqQuestTitle) {
    return null;
  }

  return (
    <HoverCard openDelay={100} closeDelay={200}>
      <HoverCardTrigger className="flex items-center gap-1 cursor-pointer">
        <LockIcon className="size-3.5 opacity-80 flex-shrink-0" />
        <span>Prerequisites needed</span>
      </HoverCardTrigger>
      <HoverCardContent
        className="bg-black/90 border-amber-500/20 text-white z-[9999] shadow-xl shadow-black/40 p-4 rounded-lg w-80"
        sideOffset={8}
      >
        <div className="flex flex-col gap-3">
          <div className="text-sm font-medium text-amber-400/90 border-b border-amber-500/20 pb-2">Required Quest:</div>
          <Link
            to={`/quest/${prereqQuestSlug}`}
            className="text-white/90 hover:text-white transition-colors flex items-center gap-2 group"
          >
            <div className="bg-amber-500/10 p-1.5 rounded-full group-hover:bg-amber-500/20 transition-colors">
              <ArrowRightIcon className="size-3.5 text-amber-400/90" />
            </div>
            <span className="underline underline-offset-2 decoration-amber-500/30 text-base">{prereqQuestTitle}</span>
          </Link>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
